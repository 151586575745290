<template>
  <div class="date--picker">
    <client-only>
      <vue-date-picker
        v-model="valueVModel"
        :format="customFormatter"
        :bootstrap-styling="bootstrapStyling"
        :disabled-dates="customDisabledDates"
        :inline="inline"
        :full-month-name="true"
        calendar-class="vue-date--picker"
        input-class="vue-date--input"
        :placeholder="$t('general.chooseStartDate')"
      />
    </client-only>
  </div>
</template>

<script>
import datePickerEn from 'vuejs-datepicker/dist/locale/translations/en.js';
// import datePickerId from 'vuejs-datepicker/dist/locale/translations/id.js';
import VueDatePicker from 'vuejs-datepicker';

export default {
  name: 'date-picker',
  props: {
    value: {},
    format: {
      type: String,
    },
    minDate: {
      type: Date,
    },
    // tanggal-tanggal yang tidak bisa dipilih, array of Date
    disabledDates: {
      type: Array,
    },
    bootstrapStyling: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Date,
    },
    from: {
      type: Date,
    },
  },
  components: { VueDatePicker },
  computed: {
    customDisabledDates() {
      if (this.to || this.from) {
        let disableDates = {};
        if (this.to) {
          disableDates.to = new Date(this.to.getFullYear(), this.to.getMonth(), this.to.getDate());
        }
        if (this.from) {
          disableDates.from = new Date(
            this.from.getFullYear(),
            this.from.getMonth(),
            this.from.getDate(),
          );
        }
        return disableDates;
      } else {
        let minDate;
        if (this.minDate === undefined) {
          let now = new Date();
          const parsedNow = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          minDate = this.$date.add(parsedNow, { days: -1 });
        } else {
          minDate = new Date(
            this.minDate.getFullYear(),
            this.minDate.getMonth(),
            this.minDate.getDate(),
          );
        }
        let dates = [];
        if (this.disabledDates !== undefined) {
          dates = this.disabledDates.map(data => {
            return new Date(data.getFullYear(), data.getMonth(), data.getDate());
          });
        }
        return {
          to: minDate,
          dates: dates,
        };
      }
    },
    valueVModel: {
      get() {
        return this.value;
      },
      set(newVal) {
        console.log('set valuevmodel', newVal);
        this.$emit('input', newVal);
      },
    },
    customFormatter() {
      return this.$date.format(this.valueVModel, this.format);
    },
    datePickerLang() {
      return datePickerEn;
      // if (this.$store.state.global.locale === 'en') {
      //   return datePickerEn;
      // } else {
      //   return datePickerId;
      // }
    },
  },
};
</script>
